import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "@emotion/styled"
import HomepageLayout from "../components/layout/index.js"
import Row from "../components/layout/row.js"
import Paginate from "../components/common/paginate"
import BackwardButton from "../components/common/backwardButton"
import SearchInput from "../components/common/searchInput"
import usePostImages from "../hooks/use-post-images"
import NewsCard from "../components/common/news-card"

const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  ${props => props.theme.tablet} {
    margin: 0;
  }
`

const BlogPostWrapper = styled.div`
  margin: 0 -0.5rem;
  ${props => props.theme.tablet} {
    margin: 0;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h1 {
    padding-bottom: 0;
  }
  margin-bottom: 1rem;
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const ColumnsNews = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 50%;
  box-sizing: border-box;
`

const PageWrapper = styled.div`
  margin-top: 31px;
`

const ColumnRow = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const BlogNewsLayout = ({
  children,
  totalPages,
  handlePageClick,
  pageNumber,
}) => {
  return (
    <HomepageLayout light>
      <Row>
        <PageWrapper>
          <BackwardButton />
          <ButtonsWrapper>
            <h1>Новости</h1>
            <SearchInput />
          </ButtonsWrapper>
          {children}
          <Paginate
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            pageNumber={parseInt(pageNumber)}
          />
        </PageWrapper>
      </Row>
    </HomepageLayout>
  )
}

const IndexPage = props => {
  const {
    data,
    pageContext: { pageNumber, totalPages },
  } = props

  const handlePageClick = data => {
    navigate(`/news/page/${data.selected + 1}`)
  }

  const postImages = usePostImages()

  const News = () => {
    if (pageNumber === 0) {
      const [
        firstNews,
        secondNews,
        thirdNews,
        fourNews,
        fiveNews,
      ] = data.wordPress.posts.nodes.slice(0, 5)

      return (
        <BlogPostWrapper>
          <LinksWrapper>
            <NewsCard
              post={firstNews}
              idx={1}
              postImages={postImages}
              flexBasis="50%"
              height="400px"
              excerpt={firstNews.excerpt}
            />
            <ColumnsNews>
              <ColumnRow>
                <NewsCard
                  post={secondNews}
                  idx={2}
                  postImages={postImages}
                  flexBasis="50%"
                  height="200px"
                />
                <NewsCard
                  post={thirdNews}
                  idx={3}
                  postImages={postImages}
                  flexBasis="50%"
                  height="200px"
                />
              </ColumnRow>
              <ColumnRow>
                <NewsCard
                  post={fourNews}
                  idx={4}
                  postImages={postImages}
                  flexBasis="50%"
                  height="200px"
                />
                <NewsCard
                  post={fiveNews}
                  idx={5}
                  postImages={postImages}
                  flexBasis="50%"
                  height="200px"
                />
              </ColumnRow>
            </ColumnsNews>
          </LinksWrapper>
          <LinksWrapper>
            {data &&
              data.wordPress &&
              data.wordPress.posts.nodes.map((post, idx) => {
                if (![0, 1, 2, 3, 4].includes(idx)) {
                  return (
                    <NewsCard
                      key={post.id}
                      post={post}
                      idx={idx + 1}
                      postImages={postImages}
                      flexBasis="25%"
                    />
                  )
                }
                return
              })}
          </LinksWrapper>
        </BlogPostWrapper>
      )
    }

    return (
      <BlogPostWrapper>
        <LinksWrapper>
          {data &&
            data.wordPress &&
            data.wordPress.posts.nodes.map((post, idx) => {
              return (
                <NewsCard
                  key={post.id}
                  post={post}
                  idx={idx}
                  postImages={postImages}
                  flexBasis="33%"
                  fontSize="1rem"
                  height="300px"
                />
              )
            })}
        </LinksWrapper>
      </BlogPostWrapper>
    )
  }

  return (
    <BlogNewsLayout
      totalPages={totalPages}
      handlePageClick={handlePageClick}
      pageNumber={parseInt(pageNumber)}
    >
      <News />
    </BlogNewsLayout>
  )
}

export default IndexPage

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wordPress {
      posts(where: { in: $ids }, first: 12) {
        nodes {
          id
          title
          uri
          slug
          excerpt
          date
          featuredImage {
            node {
              sourceUrl(size: LARGE)
            }
          }
        }
      }
    }
  }
`
