import React from "react"
import ReactPaginate from "react-paginate"
import styled from "@emotion/styled"

const PaginationButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props => props.theme.tablet} {
    justify-content: center;
  }
  font-family: "Times New Roman", Times, serif, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 3.5rem;
    ${props => props.theme.tablet} {
      padding: 0;
      margin: 1rem 0 2rem 0;
    }
  }

  li {
    display: inline-block;
    text-decoration: none;
    margin: 0 0.2rem;
    ${props => props.theme.tablet} {
      margin: 0 0.1rem;
    }
    :hover:not(.active) {
      background-color: #ddd;
      border-radius: 5px;
    }
  }

  a {
    cursor: pointer;
    color: black;
    display: block;
    padding: 8px 16px;
    ${props => props.theme.tablet} {
      padding: 6px 13px;
    }
    &:active {
      outline: 0;
      border: none;
    }
  }
  .disabled {
    a {
      color: ${props => props.theme.colors.secondary};
      cursor: not-allowed;
      &:focus {
        outline: 0;
        border: none;
      }
    }
  }

  .active {
    background-color: ${props => props.theme.colors.footer};
    a {
      color: white;
      &:focus {
        outline: 0;
        border: none;
      }
    }

    border-radius: 5px;
  }
`

const Paginate = props => {
  return (
    <PaginationButtons>
      <ReactPaginate
        previousLabel={<span>&laquo;</span>}
        nextLabel={<span>&raquo;</span>}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={props.handlePageClick}
        containerClassName={"pagination"}
        forcePage={parseInt(props.pageNumber)}
        disableInitialCallback={true}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </PaginationButtons>
  )
}

export default Paginate
