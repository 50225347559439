export function toLocalDate(date) {
  return new Date(date).toLocaleDateString("ru-Ru", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function getElementsByClassName(html, classname) {
  const div = document.createElement("div")
  div.innerHTML = html
  if (div.getElementsByClassName(classname).length > 0) {
    return div.getElementsByClassName(classname)[0].innerHTML
  }
  return ""
}

export function getAllElementsByClassName(html, classname) {
  const div = document.createElement("div")
  div.innerHTML = html
  if (div.getElementsByClassName(classname).length > 0) {
    return div.getElementsByClassName(classname)
  }
  return ""
}